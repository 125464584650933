import { AppSelector } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { IBanner, IJackpot, IStaticPage, ThemeMode } from "../types/appTypes";

const pages: AppSelector<IStaticPage[]> = (state) => state.appSlice.pages;
const banners: AppSelector<IBanner[]> = (state) => state.appSlice.banners;
const jackpot: AppSelector<IJackpot[]> = (state) => state.appSlice.jackpot;
const isMobile: AppSelector<boolean> = (state) => state.appSlice.isMobile;
const isCleanCache: AppSelector<boolean> = (state) => state.appSlice.isCleanCache;
const themeMode: AppSelector<ThemeMode> = (state) => state.appSlice.themeMode;

export const pagesSelector = createSelector(
    pages,
    (pages) => pages
)

export const bannersSelector = createSelector(
    banners,
    (banners) => banners
)

export const isMobileSelector = createSelector(
    isMobile,
    (isMobile) => isMobile
)

export const jackpotSelector = createSelector(
    jackpot,
    (jackpot) => jackpot
)

export const isCleanCacheSelector = createSelector(
    isCleanCache,
    (isCleanCache) => isCleanCache
)

export const themeModeSelector = createSelector(
    themeMode,
    (themeMode) => themeMode
)