import styled from "styled-components";

export const ListOfWinnersStyles = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
  gap: 4px;
`;

export const ItemListOfWinnersStyles = styled.li<{ bg: string }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.palette.secondary.main};
`;

export const ContentItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemNameStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NicknameStyles = styled.div`
  text-transform: capitalize;
  overflow: hidden;
  text-align: right;
  font-family: "Roboto Condensed", serif;
  color: ${({ theme }) => theme.palette.hall_of_fame.text_color};
`;

export const ItemGameStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Roboto Condensed", serif;
  }

  &:hover {
    scale: 1.1;
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ItemNumberStyles = styled.p`
  display: flex;
  justify-content: center;
  padding: 0;
  color: ${({ theme }) => theme.palette.hall_of_fame.main_color};
`;

export const ItemValueStyles = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.hall_of_fame.main_color};
  font-weight: 600;
  font-family: "Roboto Condensed", serif;
`;

export const IntroInfoStyles = styled.div<{ bg?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 450px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
`;

export const IntroWrapperStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TitlePageStyles = styled.h2`
  font-family: "Roboto Condensed", serif;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.hall_of_fame.main_color};
  white-space: pre-line;
  margin: 0;
  position: absolute;
  transform: translateY(-39px);
  transition: all 0.3s;
`;

export const MonthStyles = styled.div`
  font-family: "Roboto Condensed", serif;
  font-size: ${({ theme }) => theme.palette.hall_of_fame.font_size_primary};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.hall_of_fame.third_text_color};
  border-bottom: 1px dashed ${({ theme }) => theme.palette.hall_of_fame.border_color};
  z-index: 2;
  position: absolute;
  left: 50%;
  transition: all 0.3s;

  p {
    margin: 0;
    padding: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.hall_of_fame.main_color};
    border-bottom: 1px dashed ${({ theme }) => theme.palette.hall_of_fame.main_color};
    cursor: pointer;
  }
`;

export const MonthsListStyles = styled.ul`
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(3, minmax(0, 1fr));
  background: ${({ theme }) => theme.palette.hall_of_fame.secondary_background_color};
  border-radius: 5px;
  z-index: 5;
  margin: 0;
`;

export const MonthItemStyles = styled.li`
  font-family: "Roboto Condensed";
  height: 29px;
  padding: 4px 8px;
  text-align: left;
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.hall_of_fame.third_background_color};
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.hall_of_fame.secondary_text_color};

  &:hover {
    background: ${({ theme }) => theme.palette.hall_of_fame.background_hover};
  }
`;

export const CircleImageStyles = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;
