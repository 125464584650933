import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import useInput from "../../hooks/useInput";
import { useDebounce } from "../../hooks/useDebounce";
import Search from "../../ui/material-kit/Search";
import Modal from "../../ui/material-kit/Modal";
import Button from "../../ui/material-kit/Button";
import getProviderImage from "../../helpers/providerHelper";

interface ProvidersProps {
  onChange: (v: string) => void;
  provider?: string;
  onClose: () => void;
  isOpen: boolean;
}

const ProvidersModal = (props: ProvidersProps): JSX.Element => {
  const { t } = useTranslation();
  const { onChange, provider, onClose, isOpen } = props;
  const { providers } = useTypedSelector((state) => {
    return {
      providers: state.gamesSlice.providersList.providers,
    };
  });

  const fields = {
    title: t(`common.modals.providers`),
  };

  const search = useInput("");
  const delay = useDebounce(search, 500);

  const handleEdit = (item: string) => {
    onClose();
    onChange(item);
  };

  const images = useMemo(() => {
    return providers
      .map((item) => ({
        image: getProviderImage({ provider: item, isTvProvider: false }),
        name: item,
      }))
      .filter((item) =>
        item.name.toLowerCase().includes(delay.value.toLowerCase())
      );
  }, [providers, delay]);

  return (
    <Modal
      style={{ overflow: "hidden" }}
      isOpen={isOpen}
      onClose={onClose}
      title={fields.title}
      isFullScreen={true}
    >
      <MobileContainer>
        <Wrapper>
          {/*<div style={{width: 288}}>*/}
          <Search
            title={t(`common.placeholder.search_providers`)}
            search={search}
            isMobile={true}
          />
          {/*</div>*/}
        </Wrapper>

        <BlockContainer style={{ overflow: "overlay" }}>
          {images.map((item) => (
            <StButton
              color={"secondary"}
              isSelected={item.name === provider}
              onClick={() => handleEdit(item.name)}
              id={`provider-modal-${item.name}`}
            >
              {item.image ? (
                <Image src={item.image} alt={item.name} />
              ) : (
                item.name
              )}
            </StButton>
          ))}
        </BlockContainer>
      </MobileContainer>
    </Modal>
  );
};

export default ProvidersModal;

const MobileContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(288px, 700px);
  grid-template-rows: 40px 1fr;
  grid-row-gap: 12px;
  padding: 0 44px 10px;
  height: 100%;
`;

const Wrapper = styled.div`
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: min-content;
  height: calc(100% - 70px);
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;

  ${({ theme }) =>
    theme.mode === "light" &&
    css`
      -webkit-filter: brightness(0.5);
      filter: brightness(0.5);
    `}
`;

const StButton = styled(Button)`
  border-radius: unset;
  font-size: 14px;
`;
