import React from "react";
import styled, { css } from "styled-components";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import { getStorage } from "hooks/useStorage";
import { device } from "../../../constants/deviceSizes";
import { userSelector } from "../../../redux/selectors/userSelector";
import { isMobileSelector } from "../../../redux/selectors/appSelector";

const project = process.env.REACT_APP_INSTANCE;

const InfoContainer = (): JSX.Element => {
  const { t } = useTranslation();

  const user = useTypedSelector(userSelector);
  const isMobile = useTypedSelector(isMobileSelector);

  const currency = getStorage("APP_OTHER.CURRENCY.KEY");
  return (
    <Wrapper isMobile={isMobile}>
      <ContainerInfo style={{ justifySelf: "end" }}>
        <Title>
          {t(`common.header.id`)}
          {": "}
        </Title>
        <SecondText>{user?.id}</SecondText>
      </ContainerInfo>
      <ContainerInfo style={{ justifySelf: "start" }}>
        <Title>
          {t(`common.header.balance`)}
          {": "}
        </Title>
        <SecondText>
          {` ${user?.balance.toLocaleString()} `}
          {currency}
        </SecondText>
      </ContainerInfo>
    </Wrapper>
  );
};

export default React.memo(InfoContainer);

const Wrapper = styled.div<{ isMobile?: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? "8px" : "0px")};
  font-size: ${({ isMobile }) => (isMobile ? "10px" : "24px")};

  display: flex;
  gap: 20px;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  align-items: ${({ isMobile }) => (isMobile ? "flex-end" : "center")};
  gap: ${({ isMobile }) => (isMobile ? "2px" : "20px")};

  @media (${device.laptopM}) {
    font-size: ${({ isMobile }) => (isMobile ? "10px" : "20px")};
  }
`;

const ContainerInfo = styled.div<{ isMobile?: boolean }>`
  display: flex;
  white-space: pre;
  text-transform: uppercase;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.primary.light};
`;

const SecondText = styled.div`
  color: ${({ theme }) => theme.palette.text.main};
`;
