import { device } from "constants/deviceSizes";
import { getStorage } from "hooks/useStorage";
import { useTypedDispatch, useTypedSelector } from "hooks/useTypedRedux";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { APP_VERSION, BASE_URL } from "redux/api/commonApi";
import {
  isCleanCacheSelector,
  isMobileSelector,
} from "redux/selectors/appSelector";
import { updateCleanCache } from "redux/slices/appSlice";
import Portal from "services/PortalService";
import styled, { css } from "styled-components";
import Button from "ui/material-kit/Button";

const project = process.env.REACT_APP_INSTANCE;

export const AlertCleanCache = () => {
  const { t, i18n } = useTranslation();
  const isRight = i18n.dir() === "rtl";
  const isFrench = i18n.language === "fr";

  const { pathname } = useLocation();

  const isMobileFooter: boolean = useMemo(() => {
    return ["/games", "/tv-games"].some((item) => pathname.includes(item));
  }, [pathname]);

  const isMobile = useTypedSelector(isMobileSelector);
  const isCleanCache = useTypedSelector(isCleanCacheSelector);
  const dispatch = useTypedDispatch();

  const handleCleanCache = () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (APP_VERSION) {
      localStorage.setItem("app_version", APP_VERSION);
      (async () => {
        const response = await fetch(`${BASE_URL}/client_app_version.json`);
        const data = await response.json();
        if (data.app_version !== APP_VERSION) {
          dispatch(updateCleanCache(true));
        }
      })();
    }
  }, [dispatch]);

  return isCleanCache ? (
    <Portal id="modal-root">
      <CustomBlock
        isMobile={isMobile}
        isRight={isRight}
        isMobileFooter={isMobileFooter}
      >
        <CustomTitle isMobile={isMobile}>
          {t("common.modals.cache_clean.update_title", {
            platform: getStorage("APP_SEO.TITLE"),
          })}
          {!isMobile ? ".com" : ""}
        </CustomTitle>
        <CustomDescription isMobile={isMobile} isFrench={isFrench}>
          {t("common.modals.cache_clean.update_description", {
            platform: getStorage("APP_SEO.TITLE"),
          })}
        </CustomDescription>
        <ButtonSection isMobile={isMobile} isRight={isRight}>
          <LatterButton
            isMobile={isMobile}
            id="clear-latter-cache-button"
            onClick={() => dispatch(updateCleanCache(false))}
            extra={LatterButton}
          >
            {t("common.modals.cache_clean.later_button")}
          </LatterButton>
          <UpdateButton
            isMobile={isMobile}
            id="clear-cache-button"
            onClick={handleCleanCache}
          >
            {t("common.modals.cache_clean.update_button")}
          </UpdateButton>
        </ButtonSection>
      </CustomBlock>
    </Portal>
  ) : null;
};

const CustomBlock = styled.div<{
  isMobile: boolean;
  isRight: boolean;
  isMobileFooter: boolean;
}>`
  position: fixed;
  right: ${({ isRight }) => (isRight ? "auto" : "29px")};
  left: ${({ isRight }) => (!isRight ? "auto" : "29px")};
  bottom: 26px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: fit-content;
  height: fit-content;
  padding: ${project === "megawin" ? "40px 44px 44px 40px" : "40px"};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  @media (${device.laptop}) {
    right: ${({ isRight }) => (isRight ? "auto" : "0")};
    left: ${({ isRight }) => (!isRight ? "auto" : "0")};
    bottom: ${({ isMobileFooter }) => (isMobileFooter ? "89px" : "26px")};
    margin-inline: 13px;
  }

  ${project === "mybet" &&
  css`
    @media (${device.tabletM}) {
      margin-inline: 8px;
      padding: 23px;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    }
  `}

  ${project === "megawin" &&
  css`
    @media (${device.tabletS}) {
      margin-inline: 8px;
      padding: 23px;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    }

    @media (${device.mobileL}) {
      width: calc(100% - 16px);
      margin: 0 8px;
      box-sizing: border-box;
      padding: 34px 28px;

      > p {
        text-align: left;
      }
    }
  `}
`;

const CustomTitle = styled.p<{ isMobile: boolean }>`
  font-family: "Vazirmatn", sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.main};

  margin: 0;

  ${project === "mybet" &&
  css`
    @media (${device.tabletM}) {
      font-size: 24px;
    }
  `}

  ${project === "megawin" &&
  css`
    font-weight: 900;

    @media (${device.tabletS}) {
      font-size: 24px;
    }
  `}
`;

const CustomDescription = styled.p<{ isMobile: boolean; isFrench: boolean }>`
  max-width: ${({ isFrench }) => (isFrench ? "550px" : "419px")};
  margin-top: 16px;
  margin-bottom: 0;

  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.main};

  ${({ isFrench }) =>
    project === "mybet" &&
    css`
      @media (${device.tabletM}) {
        max-width: ${isFrench ? "345px" : "301px"};
        font-size: 14px;
        margin-top: 8px;
      }
    `}

  ${({ isFrench }) =>
    project === "megawin" &&
    css`
      @media (${device.tabletS}) {
        max-width: ${isFrench ? "345px" : "301px"};
        font-size: 14px;
        margin-top: 8px;
      }
    `}
`;

const ButtonSection = styled.div<{ isMobile: boolean; isRight: boolean }>`
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  margin-top: 24px;
  padding-bottom: 11px;

  ${project === "mybet" &&
  css`
    @media (${device.tabletM}) {
      margin-top: 16px;
      column-gap: 16px;
      justify-content: space-between;

      & > div,
      & > div > button,
      & > button {
        width: 100%;
      }
    }
  `}

  ${project === "megawin" &&
  css`
    padding-bottom: 0;

    @media (${device.tabletS}) {
      margin-top: 16px;
      column-gap: 16px;
      justify-content: space-between;

      & > div,
      & > div > button,
      & > button {
        width: 100%;
      }
    }
  `}
`;

const LatterButton = styled(Button)<{ isMobile: boolean }>`
  width: auto;
  max-width: 166px;
  background-color: ${({ theme }) => theme.palette.secondary.focused};
  color: ${({ theme }) => theme.palette.text.main};

  ${project === "mybet" &&
  css`
    @media (${device.tabletM}) {
      min-width: auto;
      max-width: 148px;
    }
  `}

  ${project === "megawin" &&
  css`
    width: 166px;

    @media (${device.tabletS}) {
      min-width: auto;
      max-width: 148px;
    }
  `}
`;
const UpdateButton = styled(Button)<{ isMobile: boolean }>`
  width: auto;
  max-width: 166px;
  color: ${({ theme }) => theme.palette.warning.main};

  ${project === "mybet" &&
  css`
    @media (${device.tabletM}) {
      min-width: auto;
      max-width: 148px;
    }
  `}

  ${project === "megawin" &&
  css`
    width: 166px;

    @media (${device.tabletS}) {
      min-width: auto;
      max-width: 148px;
    }
  `}
`;
