import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import Button from "./material-kit/Button";
import getProviderImage from "../helpers/providerHelper";
import { t } from "i18next";
import { ReactComponent as ArrowDrop } from "assets/images/header_drop-down-button_icon.svg";
import useDimensions from "react-cool-dimensions";
import { useTranslation } from "react-i18next";

const project = process.env.REACT_APP_INSTANCE;

interface IProviderAccordion {
  changeProvider: (provider: string) => void;
  currentProvider: string;
}

const ProviderAccordion = (props: IProviderAccordion) => {
  const { changeProvider, currentProvider } = props;
  const { providers } = useTypedSelector((state) => {
    return {
      providers: state.gamesSlice.providersList.providers,
    };
  });
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(40);

  const resize = () => {
    setHeight((prev) => window.innerWidth);
  };

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const fields = {
    providers: t(`pages.profile.provider`),
  };

  const handleChoose = (item: string) => {
    changeProvider(item);
    if (item === currentProvider) setOpen(false);
  };

  const images = useMemo(() => {
    return providers.map((item) => ({
      image: getProviderImage({ provider: item, isTvProvider: false }),
      name: item,
    }));
  }, [providers]);

  return (
    <Wrapper
      isOpen={isOpen}
      ref={ref}
      style={{ maxHeight: isOpen ? height : "40px" }}
    >
      <React.Fragment key={"accordion-all"}>
        <FirstStButton
          onClick={handleOpen}
          color={"secondary"}
          id={"accordion-start"}
        >
          <span>{fields.providers}</span>
          <ArrowDrop
            style={{
              transform: isOpen ? "rotate(180deg)" : "unset",
            }}
          />
        </FirstStButton>
      </React.Fragment>
      {images.map((item) => (
        <React.Fragment key={`accordion-${item.name}`}>
          <StButton
            onClick={() => handleChoose(item.name)}
            color={"secondary"}
            isSelected={currentProvider === item.name}
            id={`accordion-${item.name}`}
          >
            {item.image ? (
              <Image src={item.image} alt={item.name} />
            ) : (
              item.name
            )}
          </StButton>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default React.memo(ProviderAccordion);

const Wrapper = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  display: grid;
  border-radius: 5px;
  min-height: 58px;
  max-height: ${({ isOpen }) => (isOpen ? "100%" : "40px")};
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  font-size: 18px;
  box-sizing: border-box;

  :focus {
    background-color: unset;
  }

  ${project === "megawin" &&
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StButton = styled(Button)`
  height: 58px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;

  ${({ theme }) =>
    theme.mode === "light" &&
    css`
      img {
        -webkit-filter: brightness(0.5);
        filter: brightness(0.5);
      }
    `}

  ${project === "megawin" &&
  css`
    &:nth-child(n) {
      min-width: 144px;
      padding: 0;
    }
  `}
`;

const FirstStButton = styled(StButton)`
  svg {
    transition: 0.5s;
  }
  background-color: ${({ theme }) =>
    theme.mode === "dark"
      ? theme.palette.secondary.focused
      : theme.palette.providers?.background};
  color: ${({ theme }) => theme.palette.primary.white};

  ${project === "megawin" &&
  css`
    font-size: 24px;
    svg {
      width: 29px;
      height: 29px;
      padding: 16px;
    }

    *:first-child {
      padding-inline: 16px 0;
    }
  `}
`;
