import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import { FixedSizeGrid } from "react-window";
import openGame from "../../helpers/openGame";
import GameCard from "../../ui/GameCard";
import useDimensions from "react-cool-dimensions";
import { device } from "constants/deviceSizes";

const project = process.env.REACT_APP_INSTANCE;

interface IGamesPageContainer {
  value: {
    game?: string;
    provider?: string;
    category?: string;
  };
}

const GamesListContainerDesktop = (props: IGamesPageContainer) => {
  const { game, provider, category } = props.value;
  const [getGameList, { isLoading }] = useGetGameListMutation();
  const { data, user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
      data: state.gamesSlice.gameList
        .toArray()
        .map((item) => item[1])
        .slice(0, project === "megawin" ? 26 : 22),
    };
  });

  useEffect(() => {
    getGameList({
      data: {
        type: "update",
        title: game,
        size: 30,
        provider,
        category,
      },
    });
  }, [game, provider, category]);

  const { observe, width } = useDimensions({});

  return (
    <Wrapper
      ref={observe}
      length={data.length}
      style={{
        gridAutoRows: project !== "megawin" ? `${(width - 100) / 7}px` : "",
      }}
    >
      {data.map((item, index) => (
        <React.Fragment key={`game-${index}-${item.Name}`}>
          <GameCard game={item} isAuth={!!user} isLazy={true} />
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default GamesListContainerDesktop;

const Wrapper = styled.div<{ length: number }>`
  display: grid;
  justify-items: center;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 16px;
  padding: 8px;
  max-width: 100%;

  > div {
    width: 100%;
    height: 100%;

    ${({ length }) =>
      length > 11 &&
      css`
        :nth-child(${project === "megawin" ? 7 : 6}) {
          grid-column: 2 span;
          grid-row: 2 span;

          ${project === "megawin" &&
          css`
            > div:nth-child(2) {
              gap: 12px;
              padding-bottom: 32px;

              span:first-child {
                font-size: 36px;
                padding: 0 16px;
                line-height: 30px;
              }

              span:last-child {
                font-size: 24px;
                line-height: 20px;
              }
            }
          `}
        }
      `}
    ${({ length }) =>
      length > 12 &&
      css`
        :nth-child(${project === "megawin" ? 14 : 12}) {
          grid-column: 2 span;
          grid-row: 2 span;

          ${project === "megawin" &&
          css`
            > div:nth-child(2) {
              gap: 12px;
              padding-bottom: 32px;

              span:first-child {
                font-size: 36px;
                padding: 0 16px;
                line-height: 30px;
              }

              span:last-child {
                font-size: 24px;
                line-height: 20px;
              }
            }
          `}
        }
      `}
  }

  ${({ length }) =>
    project === "megawin" &&
    css`
      grid-template-columns: repeat(8, 1fr);
      grid-auto-flow: row dense;
      row-gap: 16px;
      column-gap: 18px;

      > div {
        width: 100%;
        height: auto;
      }

      @media (${device.laptopL}) {
        row-gap: 10px;
        column-gap: 12px;
        padding: 6px;
      }
    `}
`;
