import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactElement,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { ReactComponent as OpenedEyeIcon } from "../../assets/images/input_opened_eye_icon.svg";
import { ReactComponent as ClosedEyeIcon } from "../../assets/images/input_closed_eye_icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/input_error_icon.svg";
import useDimensions from "react-cool-dimensions";
import { formatForId } from "../../helpers/formatString";
import { regexNumber } from "constants/regExp";
import checkProps from "helpers/checkProps";
import useInput from "hooks/useInput";
import { FlattenInterpolation, ThemeProps } from "styled-components";
import { isMobileSelector } from "redux/selectors/appSelector";
import { useTypedSelector } from "hooks/useTypedRedux";

type StyleExtraType = string | FlattenInterpolation<ThemeProps<any>>;

interface IIcon {
  icon: ReactElement;
  onClick?: () => void;
  extra?: StyleExtraType;
}

export interface TextFieldProps {
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;

  onEnterPress?: () => void;

  rightIcon?: IIcon;
  leftIcon?: IIcon;
  leftContent?: string;

  isError?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;

  upContent?: string;
  type?: string;
  id?: string;
  helperText?: string;

  autoComplete?: "off" | "on" | "new-password";
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  maxLength?: number;

  extra?: StyleExtraType;
  inputExtra?: StyleExtraType;

  regex?: RegExp;
  inputRef?: React.Ref<any>;

  errorText?: string;
  setErrorText?: (v: string) => void;
  memoProps?: string[];
}

const project = process.env.REACT_APP_INSTANCE;

const Input = (props: TextFieldProps) => {
  const {
    type = "text",
    extra = ``,
    leftIcon,
    leftContent,
    rightIcon,
    inputProps,
    onChange,
    value,
    inputExtra = ``,
    placeholder = "",
    regex = props.type === "number" && props.regex === undefined
      ? regexNumber.int
      : props.regex,
    isReadOnly = false,
    isDisabled = false,
    isRequired = false,
    id,
    helperText,
    autoComplete = "new-password",
    isError,
    upContent,
    errorText,
    setErrorText,
    onEnterPress,
    inputRef,
    maxLength,
  } = props;
  let oldValue: string = value + "";

  const { observe: LeftIconRef, width: LeftIconWidth } = useDimensions({});
  const { observe: RightIconRef, width: RightIconWidth } = useDimensions({});
  const isMobile = useTypedSelector(isMobileSelector);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [stateDisplay, setStateDisplay] = useState<"none" | "block">("none");

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setErrorText) setErrorText("");
    if (e.target.value === "") {
      onChange(e);
      oldValue = e.target.value;
      return;
    }
    if (regex) {
      if (props.type === "number") {
        let correctString = e.target.value.match(regex);
        e.target.value = correctString !== null ? correctString[0] : oldValue;
      }
    }
    onChange(e);
    oldValue = e.target.value;
  };

  const handleFocus = () => {
    const input = document.getElementById(
      id || `input_${formatForId(upContent || placeholder)}`
    ) as HTMLInputElement;
    if (input) {
      input.focus();

      setTimeout(() => {
        input.setSelectionRange(input.value.length, input.value.length);
      }, 1);
    }
  };

  return (
    <Wrapper>
      {upContent && (
        <Label isError={isError || !!errorText}>
          {upContent} {isRequired && "*"}
        </Label>
      )}

      <InputWrapper
        extra={extra}
        isDisabled={isDisabled || isReadOnly}
        isError={isError || !!errorText}
      >
        {leftContent && <LeftContent>{leftContent}</LeftContent>}
        <StyledInput
          onChange={_onChange}
          disabled={isDisabled}
          value={value}
          ref={inputRef}
          required={isRequired}
          autoComplete={autoComplete}
          extra={inputExtra}
          type={
            type === "number" || (type === "password" && passwordVisible)
              ? "text"
              : type
          }
          isError={isError || !!errorText}
          readOnly={isReadOnly}
          placeholder={isRequired ? placeholder + " *" : placeholder}
          leftPadding={LeftIconWidth}
          rightPadding={RightIconWidth}
          id={formatForId(id ?? `input_${upContent || placeholder}`)}
          inputMode={type === "number" ? "numeric" : "text"}
          onKeyDown={(e) =>
            e.key === "Enter" && onEnterPress ? onEnterPress() : undefined
          }
          maxLength={maxLength}
          {...inputProps}
        />

        {leftIcon && (
          <LeftIcon
            ref={LeftIconRef}
            onClickCapture={() => {
              handleFocus();
              if (leftIcon.onClick) leftIcon.onClick();
            }}
            extra={leftIcon.extra}
            isDisabled={isDisabled}
          >
            {leftIcon.icon}
          </LeftIcon>
        )}

        <RightIconContainer
          id={`input-right-icon-${placeholder}`}
          isError={isError}
          ref={RightIconRef}
          onClickCapture={() => {
            handleFocus();
          }}
        >
          {rightIcon && (
            <RightIcon
              onClickCapture={() => {
                if (rightIcon.onClick) rightIcon.onClick();
              }}
              extra={rightIcon.extra}
              isClick={((): boolean => !!rightIcon?.onClick)()}
            >
              {rightIcon.icon}
            </RightIcon>
          )}

          {type === "password" ? (
            <EyeContainer className="password_input_eye_icon">
              {passwordVisible ? (
                <OpenedEyeIcon onClick={() => setPasswordVisible(false)} />
              ) : (
                <ClosedEyeIcon onClick={() => setPasswordVisible(true)} />
              )}
            </EyeContainer>
          ) : null}

          <ErrorContainer
            onMouseOverCapture={() => {
              if ("ontouchstart" in window || navigator.maxTouchPoints > 0)
                return;
              setStateDisplay("block");
            }}
            onTouchStart={() => {
              setStateDisplay("block");
            }}
            onMouseOutCapture={() => {
              setStateDisplay("none");
            }}
            onTouchEnd={() => {
              setStateDisplay("none");
            }}
            onClick={() => isMobile && setStateDisplay("block")}
            onBlur={() => isMobile && setStateDisplay("none")}
          >
            <>{(isError || errorText) && <ErrorIcon />}</>
          </ErrorContainer>
        </RightIconContainer>

        {(errorText || helperText) && (
          <HelperText
            display={stateDisplay}
            id={formatForId(`input-tip-${placeholder}`)}
          >
            {errorText || helperText}
          </HelperText>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

export default React.memo(Input, (prev, next) => {
  try {
    return checkProps<Readonly<TextFieldProps>>(prev, next, [
      "inputRef",
      "leftIcon",
      "rightIcon",
    ]);
  } catch (e) {
    // console.log(`Input ${next.placeholder || next.upContent || next.value} re-render`, next, e)
    return false;
  }
});

const LeftContent = styled.div`
  margin: 0 15px;
  display: flex;
  top: 50%;

  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  position: absolute;
  z-index: 2;

  ${({ theme }) =>
    theme.mode === "light" &&
    css`
      color: ${theme.palette.text.main};
    `}
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const InputWrapper = styled.div<{
  extra?: StyleExtraType;
  isError?: boolean;
  isDisabled?: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  max-width: 1980px;
  position: relative;

  &:hover > input {
    ${({ isDisabled }) =>
      !isDisabled &&
      css`
        background-color: ${({ theme }) => theme.palette.secondary.focused};
      `}
  }

  ${({ extra }) => extra}
`;

const StyledInput = styled.input<{
  extra?: StyleExtraType;
  isError?: boolean;
  leftPadding?: number;
  rightPadding?: number;
}>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.text.main};
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 11px
    ${({ rightPadding }) => (rightPadding ? rightPadding + 20 : 16)}px 11px
    ${({ leftPadding }) => (leftPadding ? leftPadding + 30 : 16)}px;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.focused};
    outline: none;
  }

  ${project === "megawin" &&
  css`
    &:hover,
    &:focus {
      & + div .password_input_eye_icon path {
        stroke: white;
      }
    }
  `}

  &:disabled {
    background-color: ${({ theme }) => theme.palette.secondary.disabled};
    color: ${({ theme }) => theme.palette.secondary.focused};
  }

  &::placeholder {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.secondary.disabled};
  }

  // Убирает стрелочки при type=number
  &:hover::-webkit-outer-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  transition: all 0.25s;

  ${({ extra }) => extra}
`;

const LeftIcon = styled.div<{ extra?: StyleExtraType; isDisabled?: boolean }>`
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 25px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  ${({ extra }) => extra}
  cursor: ${({ isDisabled }) => isDisabled && "default"};
`;

const RightIconContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  line-height: 0;
  position: absolute;
  display: flex;
  top: 50%;
  right: 0;

  -webkit-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
  cursor: ${({ isDisabled }) => isDisabled && "default"};

  & > * {
    margin-right: 7px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  & > *:nth-last-child(2) {
    margin-right: ${({ isError }) => (isError ? "7px" : "0")};
  }
`;

const RightIcon = styled.div<{ extra?: StyleExtraType; isClick?: boolean }>`
  line-height: 0;
  cursor: ${({ isClick }) => (isClick ? "pointer" : "default")};
  display: flex;
  align-items: center;

  ${({ extra }) => extra}
`;

const EyeContainer = styled.div<{ isError?: boolean }>`
  line-height: 0;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.palette.text.main};
    }
  }

  & > svg > path {
    stroke: ${({ theme }) => theme.palette.text.main};

    ${() =>
      project === "megawin" &&
      css`
        stroke: ${"#5F6368"};
      `}
  }
`;

const ErrorContainer = styled.div`
  cursor: pointer;
  line-height: 0;
  display: flex;
  align-items: center;

  &:hover > div:last-child {
    display: block;
  }
`;

const HelperText = styled.div<{ display: string; isRequestError?: boolean }>`
  display: ${({ display }) => display};
  box-sizing: border-box;
  padding: 5px;
  height: auto;
  width: auto;
  max-width: 100%;
  position: absolute;
  line-height: 20px;
  bottom: calc(100% + 5px);
  right: 0;

  background: ${({ theme }) => theme.palette.primary.main};
  opacity: 0.9;
  border-radius: 10px;
  z-index: 125;

  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 15px;
    width: 15px;
    right: 15px;
    bottom: -5px;
    transform: rotate(45deg);
    background: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.9;
    z-index: -1;
  }
`;

const Label = styled.div<{ isError?: boolean }>`
  font-family: "Roboto", serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: ${({ theme, isError }) =>
    isError ? theme.palette.error.main : theme.palette.primary.light};
`;
