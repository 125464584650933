import { Fragment, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getStorage } from "../../../hooks/useStorage";
import routingLinks from "../../../constants/routingLinks";
import { RoutingList } from "../../../interfaces";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { useCreateMondoSessionMutation } from "../../../redux/api/gamesApi";

declare const window: any;
const project = process.env.REACT_APP_INSTANCE;

interface INavbarItem {
  key: string;
  lang_key: string;
  to: string;
  component: React.ReactElement;
  mobileComponent: React.ReactElement;
  isAuth: boolean;
  iconJsx?: any;
}

const Navbar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const HEADER = getStorage("APP_OTHER.HEADER");
  const LINKS: INavbarItem[] = HEADER.MENU.LINKS.map((it: string) =>
    routingLinks.find((item) => item.key === it)
  );
  /*        .concat(Object.values(HEADER.MENU).filter((it: any) => it.key !== 'LINKS')
            .filter((item: any) => item.VISIBLE)
            .map((link:any) => ({
            key: link.KEY,
            lang_key: link.LANG_KEY,
            to: link.PATH,
            component: <div />,
            mobileComponent: <div />,
            isAuth: false,
        })))*/

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const isActive = (path: string) => {
    let pathname = window.location.pathname.toLowerCase();
    let itemPath = path.toLowerCase();
    let search = window.location.search.toLowerCase();
    const exclude = ["category=crash"];
    if (exclude.some((item) => search.includes(item))) {
      let value = exclude.find((item) => search.includes(item));
      return value ? itemPath.includes(value) : false;
    }
    return (
      itemPath.includes(pathname) &&
      pathname !== "/" &&
      !exclude.some((item) => itemPath.includes(item))
    );
  };

  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const [CreateMondoSession, { isLoading, data }] =
    useCreateMondoSessionMutation();

  const kostylDlyaSporta = (key: string) => {
    console.log(key, window.location.pathname);
    const sport = key === "SPORT" && window.location.pathname === "/sport";
    const live =
      key === "LIVE_SPORT" && window.location.pathname === "/live-sport";
    if (sport || live) {
      window.location.reload();
    }
  };

  return (
    <Wrapper>
      {LINKS.map((item: RoutingList, index: number) => (
        <Fragment key={`navbar-element-${index}-${item.lang_key}`}>
          <NavbarItem
            isSelected={isActive(item.to)}
            onClick={() => {
              kostylDlyaSporta(item.key);
              if (!item.isAuth) {
                navigate(item.to);
              } else {
                if (!!user) {
                  navigate(item.to);
                } else {
                  callToast();
                }
              }
            }}
          >
            {t(`common.navbar.${item.lang_key}`)}
          </NavbarItem>
        </Fragment>
      ))}

      {project !== "mybet" && (
        <Fragment key={`navbar-element-help`}>
          <NavbarItem onClick={() => window.Tawk_API.toggle()}>
            {t(`common.navbar.help`)}
          </NavbarItem>
        </Fragment>
      )}

      <NavbarItemHallOfFame>
        <Link to={"/the-hall-of-fame"}>{t("pages.hall_of_fame.title")}</Link>
      </NavbarItemHallOfFame>
    </Wrapper>
  );
};

export default memo(Navbar);

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 10px;
`;

const NavbarItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  cursor: pointer;
  line-height: 33px;
  padding: 8px 27px;
  font-size: 24px;
  text-transform: uppercase;
  background-size: 400%;
  color: ${({ isSelected, theme }) => isSelected ? theme.palette.primary.light : theme.palette.text.main};
  border-radius: 5px;

  &:hover,
  &:active {
    background: ${({ theme }) => theme.palette.secondary.focused};
  }
`;

const NavbarItemHallOfFame = styled.div`
  margin: 0 27px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: no-wrap;
    height: 45px;
    padding: 0 16.5px;
    color: var(--black-000000, #000);
    text-transform: uppercase;
    border-radius: 5px;
    background: ${({ theme }) => theme.palette.background.gradient};
  }
`;
