import { FastAverageColor } from "fast-average-color";
import { hslToRgb, rgbToHsl } from "helpers/formatColors";
import { useState } from "react";

/**
 * Рассчитывает средний цвет нижней части изображения и подкручивает насыщенность и яркость
 * @param imageElement - элемент img
 */

const fac = new FastAverageColor();

export const useAverageColor = (): [
  string | undefined,
  (imageElement: HTMLImageElement) => void
] => {
  const [color, setColor] = useState<string | undefined>(undefined);

  async function changeColor(imageElement: HTMLImageElement) {
    imageElement && setColor(await getGradientColor(imageElement));
  }

  async function getGradientColor(imageElement: HTMLImageElement) {
    const color = await fac.getColorAsync(imageElement, {
      top: (imageElement.naturalHeight * 9) / 10,
    });

    const hsl = rgbToHsl(color.value[0], color.value[1], color.value[2]).map(
      (value) => +value.toFixed(2)
    );

    const rgbSaturationMax = hslToRgb(
      hsl[0],
      0.9,
      hsl[2] < 0.35 ? 0.35 : hsl[2]
    );

    const rgbCSS = `rgb(${rgbSaturationMax[0]} ${rgbSaturationMax[1]} ${rgbSaturationMax[2]})`;

    return rgbCSS;
  }

  return [color, changeColor];
};
