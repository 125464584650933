import ArrowIcon from "../../../assets/images/icons/back_icon.svg";
import CrossIcon from "../../../assets/images/icons/cross_icon.svg";
import React from "react";
import styled from "styled-components";

export const Navigator = ({onBack, onClose}: { onBack?: () => void, onClose: () => void }) => (
    <ContainerNavigator>
        {onBack ? <div onClick={onBack}>
            <Icon url={ArrowIcon} />
        </div>: <div/>}
        <div onClick={onClose} style={{textAlign: "end", display: "flex", justifyContent: "end"}}>
            <Icon url={CrossIcon} />
        </div>
    </ContainerNavigator>
)


const ContainerNavigator = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 40px;
  background-color: ${({theme}) => theme.mode === 'dark' ? theme.palette.primary.black : theme.palette.background.secondHeader};
`;


const Icon = styled.div<{ url: string }>`
  position: relative;
  background: url("${props => props.url}") no-repeat center center / contain;
  cursor: pointer;
  height: 32px;
  width: 32px;
  
`
