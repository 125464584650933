export const formatForId = (v: string | number): string => {
  return `${v}`.toLocaleLowerCase().replace(/ /g, "_");
};

export const formatPhoneNumber = (phone: string | number): string => {
  //Filter only numbers from the input
  let cleaned = ("" + phone).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{4})(\d{2})(\d{2})$/);

  if (match) {
    return "+(" + match[1] + ") " + match[2] + "-" + match[3] + "-" + match[4];
  }

  return `${phone}`;
};

export const AddZero = (str: string | number, length: number = 11): string => {
  let result = `${str}`;
  for (let i = 0; i < length - `${str}`.length; i++) {
    result = "0" + result;
  }
  return result;
};

interface ClampStringOptions {
  lines: number;
  overflow: "ellipsis" | "crop";
}

export function clampText(str: string, options: ClampStringOptions) {}
