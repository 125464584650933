import { memo, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import IconButton from "./material-kit/IconButton";
import { ReactComponent as PersonLogo } from "../assets/images/header_user-profile_icon.svg";
import { ReactComponent as ArrowDrop } from "../assets/images/header_drop-down-button_icon.svg";

import SignInModal from "../components/modals/SignInModal";
import { useTypedSelector } from "../hooks/useTypedRedux";
import ProfileModal from "components/modals/ProfileModal";
import useOutsideClick from "../hooks/useOutsideClick";

const project = process.env.REACT_APP_INSTANCE;

const DesktopProfileMenu = () => {
  const { t, i18n } = useTranslation();
  const isRight = i18n.dir() === "rtl";

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenLogin, setOpenLogin] = useState<boolean>(false);
  console.log("🚀 ~ DesktopProfileMenu ~ isOpenLogin:", isOpenLogin);

  useEffect(() => {
    setOpen(false);
  }, [window.location.pathname]);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    if (user) setOpen(false);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      {user ? (
        <Wrapper ref={ref}>
          <StyledButton
            style={{ width: 81, padding: 0 }}
            color={"secondary"}
            onClick={handleClick}
            id={"navbar-button-open-profile-login"}
          >
            <>
              <PersonIcon isOpen={isOpen} isRight={isRight} />
              <ArrowDrop />
            </>
          </StyledButton>
          {isOpen && user && (
            <ProfileModal
              login={user.login}
              id={user.id}
              isOpen={isOpen}
              onClose={handleClose}
              isAbsolute={true}
            />
          )}
        </Wrapper>
      ) : (
        <Wrapper>
          <StyledButton
            color={"secondary"}
            onClick={() => setOpenLogin(true)}
            id={"navbar-button-open-profile-login"}
          >
            <span
              style={{
                margin: 0,
                fontSize: 24,
              }}
            >
              {t(`common.header.login`)}
            </span>
          </StyledButton>
          <SignInModal
            isOpen={isOpenLogin}
            onClose={() => setOpenLogin(false)}
          />
        </Wrapper>
      )}
    </>
  );
};

export default memo(DesktopProfileMenu);

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const PersonIcon = styled(PersonLogo)<{ isOpen?: boolean; isRight?: boolean }>`
  ${({ isRight }) => (isRight ? "margin-left" : "margin-right")}: 10px;

  & > path {
    fill: ${({ theme, isOpen }) => (isOpen ? theme.palette.primary.light : theme.mode === 'light' && theme.palette.text.main)};
  }
`;

const StyledButton = styled(IconButton)`
  white-space: nowrap;
  height: 42px;
  padding: 0 19px;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
`;
