import React, { memo } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { useCustomToast } from "../../../hooks/useCustomToast";

interface IPage {
  title: string;
  path: string;
  isAuth?: boolean;
}

interface IListProps {
  pages: IPage[];
}

const List = ({ pages }: IListProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });
  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const handleClick = (page: IPage) => {
    if (!page.isAuth) {
      navigate(page.path);
    } else {
      if (!!user) {
        navigate(page.path);
      } else {
        callToast();
      }
    }
    navigate(page.path);
  };

  return (
    <Wrapper>
      {pages.map((item, index) => (
        <Item key={`${item.title}-list`} onClick={() => handleClick(item)}>
          {`${item.title}`}
        </Item>
      ))}
    </Wrapper>
  );
};

export default memo(List);

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 20px;
  text-transform: uppercase;
`;

const Item = styled.div`
  width: 100%;
  cursor: pointer;

  ${({ theme }) =>
    theme.mode === "dark"
      ? css`
          :hover {
            color: rgba(255, 255, 255, 0.7);
          }

          :active {
            color: rgba(255, 255, 255, 1);
          }
        `
      : css`
          :hover {
            color: rgba(0, 0, 0, 0.7);
          }

          :active {
            color: rgba(0, 0, 0, 1);
          }
        `}
`;
