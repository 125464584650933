import { useTypedSelector } from "hooks/useTypedRedux";
import React, { PropsWithChildren } from "react";
import { themeModeSelector } from "redux/selectors/appSelector";
import { ThemeProvider } from "styled-components";
import { getTheme } from "theme";

export const HighOrderThemeProvider = ({ children }: PropsWithChildren) => {
  const themeMode = useTypedSelector(themeModeSelector);

  return (
    <ThemeProvider theme={getTheme()[themeMode] ?? getTheme().dark}>
      {children}
    </ThemeProvider>
  );
};
