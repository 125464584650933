import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import styled from 'styled-components';
import {getStorage} from 'hooks/useStorage';
import IconButton from "../../../ui/material-kit/IconButton";
import {ReactComponent as ArrowDrop} from "assets/images/header_drop-down-button_icon.svg";

type LanguageControllerProps = {
    isDesktop?: boolean;
}

const LanguageController = (props: LanguageControllerProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const {t, i18n} = useTranslation();
    const languages = getStorage("APP_OTHER.LANGUAGES");
    let languagesArr: any = Object.values(languages).filter((item: any) => item.VISIBLE);

    const refDesk = useRef(null);
    useOutsideClick(refDesk, () => setOpen(false))


    const languageData: { title: string, icon: string }[] = languagesArr.map((item: any) => ({
        title: item.KEY,
        icon: require(`../../../${item.ICON_PATH}`)
    }));


    const curLang = languageData.find(item => item.title === i18n.language);
    return (
        <div ref={refDesk}>
            <div style={{position: "relative"}}>
                <StyledButton id={`current-language-${curLang?.title}`} onClick={() => setOpen(!open)} color={'secondary'}>
                    <>
                        <LanguageImage src={curLang?.icon}
                             alt={`current-language-${curLang?.title}-icon`}/>
                        <ArrowDrop/>
                    </>
                </StyledButton>

                {open && <StyledMenuDesktop id="desktop-positioned-menu">
                    {languageData.sort((a, b) => (+a.title.includes(i18n.language) - +b.title.includes(i18n.language))).reverse().map((item, index) => (
                        <React.Fragment key={index + "lang" + item.title}>
                            <StyledMenuItemDesktop onClick={async () => {
                                await i18n.changeLanguage(item.title)
                                setOpen(false)
                            }}>
                                <img style={{width: 26, height: 26}} src={item?.icon} alt={item?.title}/>
                            </StyledMenuItemDesktop>
                        </React.Fragment>
                    ))}
                </StyledMenuDesktop>
                }
            </div>
        </div>
    )
};

export default LanguageController;


const LanguageImage = styled.img`
  width: 26px;
  height: 26px;
  margin-inline-end: 10px;
`


const StyledMenuDesktop = styled.div`
  z-index: 1000000;
  top: 0px;
  background-color:  ${({theme}) => theme.palette.secondary.main};
  padding: 1px 4px 0 6px;
  border-radius: 5px;
  position: absolute;
`


const StyledMenuItemDesktop = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 0 8px;

  & > svg {
    width: 26px;
    height: 26px;
  }
`


const StyledButton = styled(IconButton)`
  width: 81px;
  height: 42px;
  background: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
`
