import styled from "styled-components";
import navbar_hall_of_fame from "../../../assets/images/navbar_hall_of_fame_mobile.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IDrawerHallOfFameContent {
  onClose: () => void;
}

export const DrawerHallOfFameContent = (props: IDrawerHallOfFameContent) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickHallOfFame = () => {
    onClose();
    navigate("/the-hall-of-fame");
  };

  return (
    <ConatainerHallOfFame onClick={handleClickHallOfFame}>
      <div className="navbarHallOfFameText">
        {t("common.drawer.hall_of_fame")}
      </div>
      <img src={navbar_hall_of_fame} alt="navbar_hall_of_fame" />
    </ConatainerHallOfFame>
  );
};

const ConatainerHallOfFame = styled.div`
  position: absolute;
  left: 0;
  top: 255px;

  img {
    width: 93%;
  }

  .navbarHallOfFameText {
    position: absolute;
    left: 20%;
    top: 20%;
    color: ${({ theme }) => theme.palette.text.second};
    text-transform: uppercase;
  }
`;
