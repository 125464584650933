const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "475px",
  tabletXS: "525px",
  tabletS: "600px",
  tabletM: "768px",
  laptop: "1024px",
  laptopM: "1230px",
  laptopL: "1440px",
  desktop: "2560px",
  mobileDevice: "1200px",
};

export const device = {
  mobileDevice: `max-width: ${size.mobileDevice}`,
  mobileS: `max-width: ${size.mobileS}`,
  mobileM: `max-width: ${size.mobileM}`,
  mobileL: `max-width: ${size.mobileL}`,
  mobileXL: `max-width: ${size.mobileXL}`,
  tabletXS: `max-width: ${size.tabletXS}`,
  tabletS: `max-width: ${size.tabletS}`,
  tabletM: `max-width: ${size.tabletM}`,
  laptop: `max-width: ${size.laptop}`,
  laptopM: `max-width: ${size.laptopM}`,
  laptopL: `max-width: ${size.laptopL}`,
  desktop: `max-width: ${size.desktop}`,
  desktopL: `max-width: ${size.desktop}`,
};
