import React, { CSSProperties, useRef, useId } from "react";
import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { formatForId } from "helpers/formatString";
import ButtonLottie from "assets/loaders/ButtonLottie";
import useMediaQuery from "../../hooks/useMediaQuery";
import { device } from "../../constants/deviceSizes";

interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  extra?: string | FlattenInterpolation<ThemeProps<any>>;
  style?: CSSProperties;
  variant?: "primary" | "secondary" | "simple";
  color?: "primary" | "secondary" | "error" | "success";
  className?: string;
  id: string;
  isSelected?: boolean;
}

const projectName = process.env.REACT_APP_INSTANCE;

const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    isDisabled,
    extra,
    isLoading,
    style,
    color = "primary",
    className,
    id,
    isSelected,
  } = props;

  const isMobile = useMediaQuery(device.mobileDevice);

  return (
    <Wrapper
      id={formatForId(`button-${id}`)}
      extra={extra}
      style={style}
      disabled={isDisabled || isLoading}
      color={color}
      isLoading={isLoading}
      className={className}
      isSelected={isSelected}
      isMobile={isMobile}
      onClick={() => {
        if (!isDisabled && !isLoading) onClick();
      }}
    >
      {!isLoading ? children : <ButtonLottie />}
    </Wrapper>
  );
};

export default React.memo(Button);

const Wrapper = styled.button<{
  extra?: string | FlattenInterpolation<ThemeProps<any>>;
  isLoading?: boolean;
  color: string;
  isSelected?: boolean;
  isMobile?: boolean;
}>`
  min-width: 150px;
  height: 40px;
  cursor: pointer;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.palette.secondary.disabled};
    border-color: ${({ theme }) => theme.palette.secondary.disabled};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.palette.secondary.disabled};
      pointer-events: none;
    `}

  ${({ color, isMobile }) =>
    color === "primary" &&
    css`
      color: ${({ theme }) => theme.mode === 'dark' ? theme.palette.text.second : theme.palette.primary.black};
      background-color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) =>
          !isMobile && theme.palette.primary.hover};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
    `}

  ${({ color, isSelected, isMobile }) =>
    color === "secondary" &&
    css`
      color: ${({ theme }) =>
        isSelected ? theme.palette.primary.main : theme.palette.text.main};
      background-color: ${({ theme }) =>
        isSelected
          ? theme.palette.secondary.focused
          : theme.palette.secondary.main};

      &:hover {
        background-color: ${({ theme }) =>
          !isMobile && projectName !== "megawin"
            ? theme.mode === "dark"
              ? theme.palette.primary.black
              : theme.palette.primary.hover
            : theme.palette.secondary.focused};
      }

      &:active {
        background-color: ${({ theme }) => theme.palette.secondary.focused};
      }
    `}

  transition: all 0.1s;

  ${({ extra }) => extra}
`;
