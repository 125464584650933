import React, { useEffect, useRef } from 'react';
import Portal from "../../services/PortalService";
import useOutsideClick from "../../hooks/useOutsideClick";
import styled, { css } from "styled-components";
import { CSSTransition } from 'react-transition-group';

type DrawerProps = {
  onClose: () => void,
  open: boolean,
  isRight?: boolean,
  children: React.ReactNode,
  extra?: string,
  extraContainer?: string,
  extraBackground?: string,
  width?: string,
  zIndex?: number,
  backgroundColor?: string,
}

const Drawer = (props: DrawerProps) => {
  const {
    open,
    onClose,
    children,
    backgroundColor = `#FFFFFF`,
    zIndex = 10000,
    isRight = false,
    width,
    extra = ``,
    extraContainer = ``,
    extraBackground = ``,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, onClose)


  useEffect(() => {
    let body = document.getElementsByTagName("body");
    let root = document.getElementById("root");
    if (open) {
      root!.ariaHidden = "false";
      body.item(0)!.style.overflow = "hidden";
    }
    return () => {
      root!.ariaHidden = "false";
      body.item(0)!.style.overflow = "auto";
    }

  }, [open])


  return (
    <>
      <CSSTransition
        in={open}
        timeout={200}
        classNames={isRight ? "drawer-right" : "drawer"}
        unmountOnExit
      >
        <Portal id="drawer-root">
          <ModalWrapper isRight={isRight} zIndex={zIndex} extra={extra}>
            <Background extra={extraBackground} onClick={onClose} open={open} />
            <Container extra={extraContainer} backgroundColor={backgroundColor} zIndex={zIndex} width={width}>
              {children}
            </Container>
          </ModalWrapper>
        </Portal>
      </CSSTransition>
    </>
  );
};

export default Drawer;

const Container = styled.div<{ width?: string, backgroundColor: string, zIndex: number, extra: string }>`
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  height: calc(100% - 44px);
  flex: 1 0 auto;
  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  width: ${({ width }) => width};
  ${({ extra }) => extra};
  //box-shadow: 1px 0 10px #323232;
`


const ModalWrapper = styled.div<{ isRight: boolean, extra: string, zIndex: number }>`
  flex-shrink: 0;
  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  inset: 0;
  top: 0;
  outline: 0;

  ${({ isRight }) =>
    isRight ? css`
                    right: 0;
                  ` :
      css`
                    left: 0;
                  `}
  ::before, ::after {
    box-sizing: inherit;
  }
;
  ${({ extra }) => extra}

`

const Background = styled.div<{ open: boolean, extra: string }>`
  width: 200vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 1;
  top:40px;
  background-color: ${({theme}) => theme.palette.primary.black};
  transition: all 0.2s;
  opacity: ${({ open }) => !open ? 0 : 0.5};
  ${({ extra }) => extra};
`
